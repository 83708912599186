import get from 'lodash.get';

import { ctaVariants } from '@utils';

export const conversionWith2CTA = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    boxes: get(data, 'fieldConversionBox', []).map((box) => ({
      description: get(box, 'entity.fieldDescription.processed', ''),
      cta: {
        variant: ctaVariants[get(box, 'entity.fieldCta[0].entity.entityBundle', 'cta_pink')],
        label: get(box, 'entity.fieldCta[0].entity.fieldLinkCta.title', ''),
        href: get(box, 'entity.fieldCta[0].entity.fieldLinkCta.url.path', '/'),
      },
    })),
  }
}