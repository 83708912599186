import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { CaseStudiesSiteDTO } from '@DTO/CaseStudiesSiteDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface CaseStudiesSiteProps {
  pageContext?: TemplatePageContextData;
}

const CaseStudiesSite = ({ pageContext }: CaseStudiesSiteProps) => {
  const caseStudiesSite = CaseStudiesSiteDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(caseStudiesSite.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={caseStudiesSite.metaData}
        breadcrumbs={caseStudiesSite.breadcrumbs}
        stickedForm={caseStudiesSite.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={caseStudiesSite.langPath}
      >
        <PageComponents
          components={caseStudiesSite.components}
          slug={`/${pageContext?.slug || ''}`}
        />
      </Layout>
    </>
  );
};

export default CaseStudiesSite;
