import get from 'lodash.get';

export const contextImageWithCaption = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes' ? true : false,
    headline: get(data, 'fieldHeadline.processed', ''),
    description: get(data, 'fieldCopy.processed', ''),
    variant: get(data, 'fieldVariantMobile', 'variant_one'), // variant_one = slider | variant_two = column
    imagesWithCaption: get(data, 'fieldImageWithCaption', []).map((item) => ({
      title: get(item, 'entity.fieldTitle', ''),
      copy: get(item, 'entity.fieldCopy.processed', ''),
      image: get(item, 'entity.fieldImage', undefined)
        ? {
            alt: get(item, 'entity.fieldImage.alt', ''),
            url: get(item, 'entity.fieldImage.url', ''),
            title: get(item, 'entity.fieldImage.title', ''),
            webp: get(item, 'entity.fieldImage.derivative.url', ''),
          }
        : undefined,
      cta: get(item, 'entity.fieldLinkCta', undefined)
        ? {
            label: get(item, 'entity.fieldLinkCta.title', ''),
            url: get(item, 'entity.fieldLinkCta.url.path', '/'),
          }
        : undefined,
    })),
  }
}