import get from 'lodash.get';

export const cardTextVideo = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes' ? true : false,
    headline: get(data, 'fieldHeadline.processed', ''),
    firstCardVideoSide: get(data, 'fieldVariantTextAndVideo', '1') === '1' ? 'right' : 'left',
    cards: get(data, 'fieldTextAndVideo', []).map((item) => ({
      video: get(item, 'entity.fieldVideo.entity.url', undefined),
      videoUrl: get(item, 'entity.fieldVideoUrl.url.path', undefined),
      videoPoster: get(item, 'entity.fieldPoster', undefined)
        ? {
            alt: get(item, 'entity.fieldPoster.alt', ''),
            url: get(item, 'entity.fieldPoster.url', ''),
            title: get(item, 'entity.fieldPoster.title', ''),
            webp: get(item, 'entity.fieldPoster.derivative.url', ''),
          }
        : undefined,
      schemaData: get(item, 'entity.fieldName', undefined)
        ? {
            name: get(item, 'entity.fieldName', ''),
            duration: get(item, 'entity.fieldVideoDuration', ''),
            description: get(item, 'entity.fieldVideoDescription', ''),
            uploadDate: get(item, 'entity.entityCreated', ''),
          }
        : undefined,
      body: {
        textVariant: get(item, 'entity.fieldTextSetting', 'top'),
        headline: get(item, 'entity.fieldTitle', ''),
        subheadline: get(item, 'entity.fieldSubtitle', ''),
        copy: get(item, 'entity.fieldCopy.processed', ''),
        link: get(item, 'entity.fieldLinkCta', undefined)
          ? {
              label: get(item, 'entity.fieldLinkCta.title', ''),
              url: get(item, 'entity.fieldLinkCta.url.path', ''),
            }
          : undefined,
        imageTitle: get(item, 'entity.fieldImageSubHeadline', ''),
        image: get(item, 'entity.fieldImage', undefined)
          ? {
              alt: get(item, 'entity.fieldImage.alt', ''),
              url: get(item, 'entity.fieldImage.url', ''),
              title: get(item, 'entity.fieldImage.title', ''),
              webp: get(item, 'entity.fieldImage.derivative.url', ''),
            }
          : undefined,
      },
    })),
  };
};
