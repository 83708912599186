import get from 'lodash.get';

// === Data === //
import { caseStudiesSiteData } from '@graphql/CaseStudiesSite';

// === Helpers === //
import { getNodeEntity } from '@utils';

// === Sections === //
import { metatags } from '@DTO/sections/Metatags';
import { mainBanner } from '@DTO/sections/MainBanner';
import { cardTextVideo } from '@DTO/sections/CardTextVideo';
import { caseStudy } from '@DTO/sections/CaseStudy';
import { ourCustomerLogo } from '@DTO/sections/OurCustomerLogo';
import { conversionWith2CTA } from '@DTO/sections/ConversionWith2CTA';
import { contextImageWithCaption } from '@DTO/sections/ContextImageWithCaption';
import { recommendedArticles } from '@DTO/sections/RecommendedArticles';
import { breadcrumbs } from '@DTO/sections/Breadcrumbs';
import { newsletter } from '@DTO/sections/form/Newsletter';
import { getDataFromPreview } from '@helpers/form';
import { languagePath } from '@DTO/sections/LanguagePath';

export const CaseStudiesSiteDTO = (uuid: string | undefined, data?: { drupalData: unknown }) => {
  let currentPageData: { drupalData: unknown };

  if (!data) {
    const caseStudiesSiteQuery = caseStudiesSiteData();
    currentPageData = caseStudiesSiteQuery;
  } else {
    currentPageData = data;
  }

  const nodeEntity = getNodeEntity(uuid, currentPageData, data);
  const articlesEntity = get(currentPageData, 'drupalData.blogArticles.entities', [])
    .sort((a, b) => {
      const dateA: any = new Date(get(a, 'fieldPublicationDate.value', ''))
      const dateB: any = new Date(get(b, 'fieldPublicationDate.value', ''))

      return dateB - dateA;
    });
  const formId = get(nodeEntity, 'fieldForm.entity.uuid', '');
  const additionalData = data
    ? {
        form: get(data, 'drupalData.form'),
      }
    : undefined;

  const queryData = {
    metaData: metatags(nodeEntity, 'fieldMetatags'),
    breadcrumbs: breadcrumbs(nodeEntity, ['fieldCategoryA']),
    stickedForm: get(nodeEntity, 'fieldFormOffer[0]', 'no') === 'yes',
    langPath: languagePath(nodeEntity, 'entityTranslations', 'entityMultisiteTranslations'),
    components: [
      mainBanner(nodeEntity, 'fieldMainBanner'),
      cardTextVideo(nodeEntity, 'fieldCardTextVideo'),
      caseStudy(nodeEntity, 'fieldCaseStudy'),
      recommendedArticles(nodeEntity, articlesEntity, 'fieldRecommendedArticles'),
      ourCustomerLogo(nodeEntity, 'fieldOurCustomerLogo'),
      conversionWith2CTA(nodeEntity, 'fieldConversion2Cta'),
      contextImageWithCaption(nodeEntity, 'fieldContextImageWithCaption'),
      formId && formId !== '' && newsletter(formId, getDataFromPreview(additionalData)),
    ],
  };

  return {
    ...queryData,
  };
};
