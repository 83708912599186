import get from 'lodash.get';

import { formData } from '@graphql/Form';
import { fieldType } from '@helpers/form';
import { validation } from '@DTO/sections/form/Validation';

import { INewsletter } from '@components/sections/Newsletter/Newsletter.d';

export const newsletter = (formId: string, formDataPreview?: unknown) => {
  const dFormData = formDataPreview || formData();
  const form =
    formId && formId !== ''
      ? get(dFormData, 'drupalData.nodeQuery.entities', []).find((f) => f.uuid === formId)
      : undefined;

  const mappedData: INewsletter = {
    type: 'newsletter',
    title: get(form, 'fieldHeadline', ''),
    description: get(form, 'fieldCopy2.processed', ''),
    button: get(form, 'fieldFormsRef[0].entity.fieldCtaText', ''),
    fields: get(form, 'fieldFormsRef[0].entity.fieldFormItemRef', []).map((field) => {
      return {
        type: fieldType[get(field, 'entity.fieldFieldType', 'string')].type,
        inputType: fieldType[get(field, 'entity.fieldFieldType', 'string')].inputType,
        placeholder: get(field, 'entity.fieldPlaceholder', ''),
        name: get(field, 'entity.fieldFieldName', ''),
        label: get(field, 'entity.fieldFieldLabel', ''),
        validation: validation(field),
        fieldFieldType: get(field, 'entity.fieldFieldType', 'string'),
      };
    }),
    formName: get(form, 'fieldFormsRef[0].entity.fieldFormName', ''),
    success: {
      title: get(form, 'fieldTitle', ''),
      description: get(form, 'fieldCopy.processed', ''),
    },
    language: get(form, 'fieldFormsRef[0].entity.entityLanguage.id', ''),
    id: get(form, 'nid'),
    uuid: get(form, 'uuid', ''),
  };

  return mappedData;
};
