import { IOurCustomerLogo } from '@components/sections/OurCustomerLogo/OurCustomerLogo.d';
import get from 'lodash.get';

export const ourCustomerLogo = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  const componentData: IOurCustomerLogo = {
    type: get(data, 'entityBundle', ''),
    background:
      get(data, 'fieldBackground[0]', 'no') === 'yes'
        ? true
        : false,
    headline: get(data, 'fieldHeadline.processed', ''),
    companies: get(data, 'fieldLogosUrl', []).map((logo) => ({
      url: get(logo, 'entity.fieldImage.url', ''),
      alt: get(logo, 'entity.fieldImage.alt', ''),
      title: get(logo, 'entity.fieldImage.title', ''),
      webp: get(logo, 'entity.fieldImage.derivative.url', ''),
      link: get(logo, 'entity.fieldLogoLink.url.path'),
    })),
    variant: get(data, 'fieldVariantGrid', '1'),
  }

  return componentData;
}