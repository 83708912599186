import get from 'lodash.get';

import { globalTranslations } from '@helpers/globalTranslations';
import { IBreadcrumbs } from '@components/common/Breadcrumbs/Breadcrumbs.d';

export const breadcrumbs = (nodeData, fieldName: string[]) => {
  const componentData: IBreadcrumbs = {
    list: [
      // homepage
      {
        current: false,
        label: globalTranslations('fieldHome'),
        url: '/',
      },
      // subpages between homepage and current page
      ...fieldName.map((name) => ({
        current: false,
        url: get(nodeData, `${name}.entity.path.alias`, '/'),
        label: get(nodeData, `${name}.entity.title`, ''),
      })),
      // current page
      {
        current: true,
        label: get(nodeData, 'title', ''),
        url: get(nodeData, 'path.alias', '/'),
      },
    ],
  };

  return componentData;
};
