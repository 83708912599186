import get from 'lodash.get';

import { ctaVariants } from '@utils';

export const mainBanner = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);

  return {
    type: get(data, 'entityBundle', ''),
    title: get(data, 'fieldTitle', ''),
    description: get(data, 'fieldCopyCenter.processed') || get(data, 'fieldCopyLeft.processed', ''),
    image: {
      desktop: {
        url: get(data, 'fieldImageDesktopJpg.url', ''),
        alt: get(data, 'fieldImageDesktopJpg.alt', ''),
        title: get(data, 'fieldImageDesktopJpg.title', ''),
        webp: get(data, 'fieldImageDesktopJpg.derivative.url', ''),
      },
      mobile: {
        url: get(data, 'fieldImageMobileJpg.url', ''),
        alt: get(data, 'fieldImageMobileJpg.alt', ''),
        title: get(data, 'fieldImageMobileJpg.title', ''),
        webp: get(data, 'fieldImageMobileJpg.derivative.url', ''),
      },
    },
    buttons: get(data, 'fieldCta', []).map((cta) => ({
      variant: ctaVariants[get(cta, 'entity.entityBundle', 'cta_gray')],
      label: get(cta, 'entity.fieldLinkCta.title', ''),
      href: get(cta, 'entity.fieldLinkCta.url.path', '/'),
    })),
    backgroundVideo: get(data, 'fieldBackgroundVideo.entity.url', undefined),
    schemaData: get(data, 'fieldName', undefined) ? {
      name: get(data, 'fieldName', ''),
      duration: get(data, 'fieldVideoDuration', ''),
      description: get(data, 'fieldVideoDescription', ''),
      uploadDate: get(data, 'entityCreated', '')
    } : undefined,
  };
};
