import get from 'lodash.get';

export const caseStudy = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}[0].entity`, undefined);

  return {
    type: get(data, 'entityBundle', 'case_study'),
    cases: get(data, 'fieldCaseStudy', []).map(caseStudy => ({
      copy: get(caseStudy, 'entity.fieldCopy.processed', ''),
      headline: get(caseStudy, 'entity.fieldHeadline.processed', ''),
      link: get(caseStudy, 'entity.fieldLinkCta') ? {
        title: get(caseStudy, 'entity.fieldLinkCta.title', ''),
        url: get(caseStudy, 'entity.fieldLinkCta.url.path', ''),
      } : undefined,
      subHeadline: get(caseStudy, 'entity.fieldSubheadline', ''),
    }))
  }
}