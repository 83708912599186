import get from 'lodash.get';
import { globalTranslations } from '@helpers/globalTranslations';
import { IRecommendedArticles } from '@components/sections/RecommendedArticles/RecommendedArticles.d';
import { filterByLanguage } from '@utils';

export const recommendedArticles = (nodeData, articlesData, fieldName: string) => {
  const noNullDataArticles = articlesData.filter(article => get(article, 'uuid'));
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const filteredCategory = get(data, 'fieldCategory.entity.name', undefined);
  const filteredArticles = filteredCategory
    ? noNullDataArticles.filter(article => 
      get(article, 'fieldBlogCategory.entity.name', '') === filteredCategory
    ).filter(filterByLanguage)
    : noNullDataArticles.filter(filterByLanguage);
  const readMore: string = globalTranslations('fieldCtaRecommendedArticles');

  const mappedData: IRecommendedArticles = {
    type: get(data, 'entityBundle', ''),
    headline: get(data, 'fieldHeadline.processed', ''),
    background: get(data, 'fieldBackground[0]', 'no') === 'yes',
    articles: filteredArticles.filter((article, index) => index <= 2).map(article => ({
      category: get(article, 'fieldBlogCategory.entity.name', ''),
      publicDate: get(article, 'fieldPublicationDate.value', undefined),
      readingTime: get(article, 'fieldReadingTime', ''),
      headline: get(article, 'title', ''),
      textIntro: get(article, 'fieldShortIntroCopy.processed', ''),
      image: get(article, 'fieldTeaserImage', undefined) ? {
        alt: get(article, 'fieldTeaserImage.alt', ''),
        title: get(article, 'fieldTeaserImage.title', ''),
        url: get(article, 'fieldTeaserImage.url', ''),
        webp: get(article, 'fieldTeaserImage.derivative.url', undefined),
      } : undefined,
      uuid: get(article, 'uuid', ''),
      buttonLabel: `${readMore}`,
      buttonUrl: get(article, 'path.alias', '/')
    })),
  };

  return mappedData;
}
