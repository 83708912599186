import get from 'lodash.get';

export const languagePath = (nodeData, field1Name: string, field2Name: string) => {
  const translations = get(nodeData, `${field1Name}`, []).map(t => {
    const code = get(t, 'entityLanguage.id');
    const path = get(t, 'path.alias');
    const pathWithNoSlash = !!path ?
      path.split(`/${code}/`).filter(item => !!item).join('') :
      null;
    const status = get(t, 'status', false);

    return {
      code,
      path: !!pathWithNoSlash ? pathWithNoSlash[0] === '/' ? path : `/${path}` : null,
      status
    };
  });
  const multisiteTranslations = get(nodeData, `${field2Name}`, []).map(mt => {
    const mTranslations = get(mt, 'entity.entityTranslations', []).map(t => ({
      code: get(t, 'entityLanguage.id'),
      path: get(t, 'path.alias'),
      status: get(t, 'status', false),
    }))
    
    return mTranslations
  }).flat();
  const currentLang = {
    code: process.env.API_LANGUAGE_CODE,
    path: typeof window !== 'undefined' ? (window?.location?.pathname || '').slice(0, -1) : '',
    status: true,
  }

  return [
    ...translations,
    ...multisiteTranslations,
    currentLang
  ]
  .filter(locale => !!locale.code && !!locale.path && !!locale.status);
}
